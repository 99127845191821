import { PolarGrid, PolarRadiusAxis, Radar, RadarChart } from 'recharts'
import * as React from 'react'
import cn from 'classnames'
import { getArcWidth, getData, getPolarAngles, getPolarRadius } from './utils'
import { SkillsRadarChartProps } from './skills-radar-chart.types'

export const SkillsRadarChart: React.VFC<SkillsRadarChartProps> = ({
  children,
  hideLevels = false,
  maxLevel: externalMaxLevel,
  onClick,
  polarGridColour = '#E7E4E4',
  series,
  size,
  loading,
}) => {
  const { categoryCount, data, maxLevel } = React.useMemo(() => {
    return getData(series, externalMaxLevel)
  }, [externalMaxLevel, series])

  const arcWidth = React.useMemo(() => {
    return getArcWidth(data.length)
  }, [data])

  const polarAngles = React.useMemo(() => {
    return getPolarAngles(arcWidth, categoryCount)
  }, [arcWidth, categoryCount])

  const polarRadius = React.useMemo(() => {
    return getPolarRadius(hideLevels, maxLevel, size)
  }, [hideLevels, maxLevel, size])

  return (
    <RadarChart
      className={cn({
        '!cursor-pointer': onClick !== undefined,
        'animate-pulse': loading,
      })}
      data={data}
      endAngle={-360}
      height={size + 5}
      onClick={(_nextState, e) => {
        e.stopPropagation()
        onClick?.()
      }}
      outerRadius={size / 2}
      startAngle={0}
      width={size + 5}
    >
      <PolarGrid
        gridType="circle"
        outerRadius={size / 2}
        polarAngles={polarAngles}
        polarRadius={polarRadius}
        stroke={polarGridColour}
      />
      <PolarRadiusAxis
        domain={[-1, maxLevel]}
        stroke="#00000000"
        tick={false}
      />
      {children}
      {Object.entries(series).map(([name, set]) => (
        <Radar
          className={set.className}
          dataKey={name}
          dot={set.dot}
          fill={set.colour}
          fillOpacity={0.2}
          key={name}
          stroke={set.colour}
        />
      ))}
    </RadarChart>
  )
}
