import {
  DataPoint,
  SkillsRadarChartDataPoint,
  SkillsRadarChartSeries,
} from './skills-radar-chart.types'

export const getArcWidth = (dataLength: number) => {
  return 360 / dataLength
}

export const getData = (
  series: SkillsRadarChartSeries,
  externalMaxLevel?: number
) => {
  const categoryCount: Record<string, number> = {}
  const map: Map<string, DataPoint> = new Map()
  const seriesNames = Object.keys(series)

  let maxLevel = externalMaxLevel ?? 0

  Object.entries(series).forEach(([seriesName, set]) => {
    set.skills.forEach((skill) => {
      let existing = map.get(skill.id)

      if (!existing) {
        existing = createDefaultDataPoint(seriesNames, skill)
        categoryCount[skill.category] ||= 0
        categoryCount[skill.category]++
      }

      map.set(skill.id, { ...existing, [seriesName]: skill.level })

      maxLevel = Math.max(maxLevel, skill.level + 1)
    })
  })

  const data = Array.from(map.values()).sort((a, b) => {
    if (a.category === b.category) {
      return a.label.localeCompare(b.label)
    } else {
      return a.category.localeCompare(b.category)
    }
  })

  return { categoryCount, data, maxLevel }
}

export const getPolarAngles = (
  arcWidth: number,
  categoryCount: Record<string, number>
) => {
  const categories = Object.keys(categoryCount)
  if (categories.length === 0) return [90, 210, 330]
  if (categories.length === 1) return []

  return categories
    .sort((a, b) => a.localeCompare(b))
    .reduce(
      (arr, key, i, original) => {
        if (i === original.length - 1) return arr

        const previousTotal = arr[i]
        const currentCategoryWidth = categoryCount[key] * arcWidth

        return [...arr, previousTotal - currentCategoryWidth]
      },
      [arcWidth / 2] as number[]
    )
}

export const getPolarRadius = (
  hideLevels: boolean,
  maxLevel: number,
  size: number
) => {
  const radius: number[] = []
  const outerRadius = size / 2
  if (hideLevels) return [outerRadius]

  const numberOfSections = maxLevel === 0 ? 4 : maxLevel + 1
  const sectionSize = outerRadius / numberOfSections

  for (let i = sectionSize; i <= outerRadius; i = i + sectionSize) {
    radius.push(i)
  }

  return radius
}

const createDefaultDataPoint = (
  seriesNames: string[],
  skill: SkillsRadarChartDataPoint
) => {
  const dataPoint: DataPoint = {
    id: skill.id,
    category: skill.category,
    label: skill.name,
  }

  if (skill.meta) dataPoint.meta = skill.meta

  return seriesNames.reduce(
    (point, seriesName) => ({ ...point, [seriesName]: 0 }),
    dataPoint
  )
}
